<template>
  <div class="mx-a mb-16">
    <div class="category-wrap">
      <div class="category-header-wrap">홈 안내글 설정</div>
      <div class="category-content-wrap">
        <table class="search-table">
          <tr>
            <th v-for="(fld, fldIdx) in tblFld" :key="fldIdx">{{ fld }}</th>
          </tr>
          <tr v-for="(row, rowIdx) in tblRow" :key="rowIdx">
            <td style="width: 20%">{{ row.categoryTitle }}</td>
            <td style="width: 40%">{{ row.content }}</td>
            <td style="width: 30%" v-if="row.expiryDate !== 'NULL'">
              {{ row.expiryDate }}일
            </td>
            <td style="width: 30%" v-else>항시</td>
            <td style="width: 10%">
              <button-common
                :size="SIZE_T"
                :clr="CLR_W"
                @click="openAppInfo(row)"
                >수정</button-common
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemAppInfo",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      tblFld: ["카테고리", "내용", "유지기간", "설정"],
      tblRow: [],
      toRoute: {
        path: "",
        meta: "",
      },
    };
  },

  /*** created ***/
  /*** created hook ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    await this.getData();
  },

  // beforeRouteLeave(to, from, next) {
  //   this.toRoute.path = to.fullPath;
  //   this.toRoute.meta = to.meta.id;
  //   if (this.isReset) {
  //     next();
  //   } else {
  //     next(false);
  //     if (!this.isReset) {
  //       this.onPageReset();
  //     }
  //   }
  // },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE"]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (!val) {
        this.getData();
      }
    },
  },

  /*** methods ***/
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      const rs = await this.$axios({
        url: "/admin/system/app",
        params: {},
      });
      if (rs.result === "SUC") {
        this.tblRow = rs.resultList;
      }
    },
    async openAppInfo(row) {
      await this.$store.dispatch("A_APPINFO_DATA", row);
      await this.$openAppInfo({}, this);
    },
  },
};
</script>

<style scoped lang="scss">
.category-wrap {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #d8dae5;
}
.category-header-wrap {
  width: 100%;
  border-bottom: 1px solid #d8dae5;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.category-content-wrap {
  padding: 50px 32px 80px 32px;
  display: flex;
}
</style>
